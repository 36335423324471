import { Component, Inject, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations'
import { DOCUMENT } from '@angular/common'
import { AuthService } from 'src/app/services/common/auth.service'
import { Title } from '@angular/platform-browser'
import { GlobalService } from 'src/app/services/common/global.service'
import { DocumentService } from 'src/app/services/document.service'
import { FolderService } from 'src/app/services/folder.service'
import { Document as DocumentRes, Folder } from 'src/app/Model/document'

@Component({
  selector: 'layout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutMainComponent implements OnInit {
  settings: any

  touchStartPrev: Number = 0
  touchStartLocked: Boolean = false
  showLoader: Boolean = false
  favIconRandomNumber = 2
  trashDocInfo: Array<DocumentRes> = []
  trashFolderInfo: Array<Folder> = []
  isTeamUrl: boolean
  teamName: string

  constructor(
    private store: Store<any>,
    @Inject(DOCUMENT) private document: Document,
    private auth: AuthService,
    private titleService: Title,
    private globalService: GlobalService,
    private _docService: DocumentService,
    private _folderService: FolderService,
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state
    })
  }

  ngOnInit() {

    // const head = this.document.getElementsByTagName('head')[0]
    // this.globalService.customAppSetting.subscribe(data => {
    //   if (data) {
    //     this.generateRandomNumber()
    //     const customStyleElement = this.document.getElementById('custom-css')
    //     if (customStyleElement) {
    //       head.removeChild(customStyleElement)
    //     }
    //     const customFavIcon = this.document.getElementById('custom-fav-icon')
    //     if (customFavIcon) {
    //       head.removeChild(customFavIcon)
    //     }
    //     const style = this.document.createElement('link')
    //     style.id = 'custom-css'
    //     style.rel = 'stylesheet'
    //     style.type = 'text/css'
    //     style.href = data.customCss != null ? data.customCss : 'custom-style.css'
    //     head.appendChild(style)
    //     const favIcon = this.document.createElement('link')
    //     favIcon.rel = 'icon'
    //     favIcon.type = 'image/*'
    //     favIcon.id = 'custom-fav-icon'
    //     favIcon.href = data.favicon + '?' + this.favIconRandomNumber
    //     head.appendChild(favIcon)
    //     this.titleService.setTitle(data.title)
    //     this.store.dispatch(
    //       new SettingsActions.SetStateAction({
    //         logo: data.logo,
    //       }),
    //     )
    //   }
    // })

    // this.auth.getAppSettings(data => {
    //   console.log('-----------------called', data);
    //   // this.backgroundImage = data.backgroundImage
    //   this.document.getElementById('custom-css').remove()
    //   // const head = this.document.getElementsByTagName('head')[0]
    //   // const style = this.document.createElement('link')
    //   // style.id = 'custom-css'
    //   // style.rel = 'stylesheet'
    //   // style.type = 'text/css'
    //   // style.href = data.customCss != null ? data.customCss : 'custom-style.css'
    //   // head.appendChild(style)
    //   // const favIcon = this.document.createElement('link')
    //   // favIcon.rel = 'icon'
    //   // favIcon.type = 'image/*'
    //   // favIcon.href = data.favicon
    //   // head.appendChild(favIcon)
    //   // this.titleService.setTitle(data.title)
    //   // this.store.dispatch(
    //   //   new SettingsActions.SetStateAction({
    //   //     logo: data.logo,
    //   //   }),
    //   // )
    // })

    this.globalService.isTeamUrl.subscribe(isTeamUrl => {
      this.isTeamUrl = isTeamUrl
    })
    this.globalService.teamName.subscribe(teamName => {
      this.teamName = teamName
    })

    if(!this.isTeamUrl && this.teamName == '') {
      this.getTrashInfo()
    }

    this.bindMobileSlide()
    this.globalService.showLoader.subscribe(showLoader => {
      this.showLoader = showLoader
    })
  }

  getTrashInfo() {
    this._docService.trashedDocuments({ folderId: 0 }).subscribe(
      res1 => {
        this._folderService.trashedFolder({ folderId: 0 }).subscribe(
          res => {
            this.globalService.trashFolderInfo.next(res?.length ? true : false)
            this.globalService.trashDocInfo.next(res1?.length ? true : false)
          },
          err => {},
        )
      },
      err => console.log('err', err),
    )
  }

  generateRandomNumber() {
    this.favIconRandomNumber = Math.round(Math.random() * (10 - 1 + 1) + 1)
  }

  onCollapse(value: any) {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: value,
      }),
    )
  }

  toggleCollapsed() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: !this.settings.isMenuCollapsed,
      }),
    )
  }

  toggleMobileMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMobileMenuOpen: !this.settings.isMobileMenuOpen,
      }),
    )
  }

  bindMobileSlide() {
    // mobile menu touch slide opener
    const unify = e => {
      return e.changedTouches ? e.changedTouches[0] : e
    }
    document.addEventListener(
      'touchstart',
      e => {
        const x = unify(e).clientX
        this.touchStartPrev = x
        this.touchStartLocked = x > 70 ? true : false
      },
      { passive: false },
    )
    document.addEventListener(
      'touchmove',
      e => {
        const x = unify(e).clientX
        const prev = this.touchStartPrev
        if (x - <any>prev > 50 && !this.touchStartLocked) {
          this.toggleMobileMenu()
          this.touchStartLocked = true
        }
      },
      { passive: false },
    )
  }

  routeAnimation(outlet: RouterOutlet) {
    return outlet.isActivated && outlet.activatedRoute.routeConfig.path
  }
}
