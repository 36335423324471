import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { BehaviorSubject } from 'rxjs'
import { B } from '@angular/cdk/keycodes'
import { Folder, Document as DocumentRes } from 'src/app/Model/document'
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  allFilesWidth = new Subject<number>()
  authToken: any = new BehaviorSubject<any>(null)
  cardInfo = new Subject<any>()
  clickOutsideStatus = new BehaviorSubject<boolean>(true)
  contextComponent = new Subject()
  contextMenu = new Subject<object>()
  contextMenuDisplay = new Subject<string>()
  contextMenuEvent = new BehaviorSubject<any>(null)
  currentDocument = new BehaviorSubject<any>(null)
  currentNode = new BehaviorSubject<any>(null)
  // {children: null, length: null, hasChildren: true, id: 0, isExpanded: false, lastModified: null, name: "My Documents", parent: 0, star: 0, userId: localStorage.getItem('userId'), userName: null}
  currentPage = new BehaviorSubject<any>('dashboard')
  currentVersion = new BehaviorSubject<number>(1)
  currentSelectedItem = new BehaviorSubject<any>(null)
  currentSelectedStatus = new BehaviorSubject<any>(null)
  delete = new Subject<string>()
  docDetail = new BehaviorSubject<any>(null)
  docDetailInfo = new BehaviorSubject<any>(null)
  docInfo = new BehaviorSubject<any>([])
  newDocumentAdded = new BehaviorSubject<DocumentRes>(null)
  onlineUserInfo = new BehaviorSubject<any>([])
  offlineUserInfo = new BehaviorSubject<any>([])
  refreshWebLinkCount = new BehaviorSubject<any>([])
  docLogDetail = new BehaviorSubject<any>(null)
  documentVersionsArr = new BehaviorSubject<any>([])
  expiry = new Subject<string>()
  folderBCObj = new BehaviorSubject<any>([{ folderId: null }])
  folderBreadCrumb = new BehaviorSubject<boolean>(false)
  folderDetail = new BehaviorSubject<any>(null)
  folderLogDetail = new BehaviorSubject<any>(null)
  folderObj = new BehaviorSubject<any>([])
  folderNodes = new BehaviorSubject<any>([])
  newFolderAdded = new BehaviorSubject<Folder>(null)
  folderTreeContextMenu = new BehaviorSubject<any>(null)
  gridView = new BehaviorSubject<boolean>(true)
  iFramed: boolean = false
  listView = new BehaviorSubject<boolean>(false)
  mainContentEle = new BehaviorSubject<any>(null)
  menu = new Subject<string>()
  moreActions = new Subject<string>()
  moveFile = new Subject<string>()
  newNode = new BehaviorSubject<any>([])
  quickAccessWidth = new Subject<number>()
  quickShare = new Subject<string>()
  reinitLayout = new Subject<string>()
  rename = new Subject<string>()
  searchFlag = new BehaviorSubject<boolean>(false)
  searchItemName = new BehaviorSubject<any>(null)
  searchItemPathArr = new BehaviorSubject<any>([])
  selectedDocumentArr = new BehaviorSubject<any>([])
  selectedFolderArr = new BehaviorSubject<any>([])
  selectedFolderItem = new BehaviorSubject<any>([])
  selectedFolderObj = new BehaviorSubject<any>({})
  selectedItem = new BehaviorSubject<any>([])
  selectedQuickAccessItem = new BehaviorSubject<any>(null)
  setHeaderWrapperLeft0 = new Subject<boolean>()
  setPageContainerPadding0 = new Subject<boolean>()
  setTableView = new BehaviorSubject<boolean>(false)
  setVisible = new BehaviorSubject<boolean>(false)
  sharedDirId = new BehaviorSubject<number>(0)
  sharedDocId = new BehaviorSubject<number>(0)
  sharedStatus = new BehaviorSubject<boolean>(false)
  starBtn = new BehaviorSubject<any>(true)
  superParentFolderId = new BehaviorSubject<any>(null)
  totalSpace = new BehaviorSubject<any>(0)
  useWebDataUsed = new BehaviorSubject<any>(0)
  useWebDataCount = new BehaviorSubject<any>(0)
  treeObj = new BehaviorSubject<any>(null)
  usedPercentage = new BehaviorSubject<any>(0)
  usedSpace = new BehaviorSubject<any>(0)
  userInfo = new BehaviorSubject<any>(null)
  userSetting = new BehaviorSubject<any>({})
  wfStatusArr = new BehaviorSubject<any>([])
  wfStatusListArr = new BehaviorSubject<any>([])
  setSidebarView = new Subject<boolean>()
  isLoading = new BehaviorSubject<boolean>(false)
  thumbUploadFailed = new BehaviorSubject<boolean>(false)
  dropBoxFolderBCObj = new BehaviorSubject<any>([])
  dropBoxFolderObj = new BehaviorSubject<any>([])
  dropBoxFileObj = new BehaviorSubject<any>([])
  isAuthenticated = new BehaviorSubject<boolean>(false)
  isAuthenticatedDropBox = new BehaviorSubject<boolean>(false)
  authUrl = new Subject<string>()
  uploadSourceCurrentFolder = new Subject<string>()
  anonymousUserBreadCrum = new BehaviorSubject<any>([])
  isPermission = new BehaviorSubject<any>(0)
  isCustomUrl = new BehaviorSubject<boolean>(false)
  customAppSetting = new BehaviorSubject<any>([])
  isCustomUrlOwner = new BehaviorSubject<boolean>(false)
  isDropBoxDoc = new BehaviorSubject<string>('')
  isDragging = new BehaviorSubject<boolean>(false)
  sortBy = new BehaviorSubject<string>('')
  filter = new BehaviorSubject<string>('filter')
  filterType = new BehaviorSubject<string>('type')
  sortArrow = new BehaviorSubject<boolean>(false)
  sortValue = new BehaviorSubject<string>('')
  filterArray = new BehaviorSubject<any>([])
  notification = new BehaviorSubject<any>(false)
  detailwrap = new BehaviorSubject<any>(false)
  forgotSuccess = new BehaviorSubject<any>(false)
  detail = new BehaviorSubject<any>(false)
  notificationCheck = new BehaviorSubject<any>('')
  userPermission = new BehaviorSubject<any>({})
  vimeoFileObj = new BehaviorSubject<any>([])
  vimeoFolderObj = new BehaviorSubject<any>([])
  vimeoFolderBCObj = new BehaviorSubject<any>([])
  youTubeFileObj = new BehaviorSubject<any>([])
  youTubeFolderObj = new BehaviorSubject<any>([])
  youTubeFolderBCObj = new BehaviorSubject<any>([])
  updateLocalData = new BehaviorSubject<any>({
    dashboard: true,
    shared: true,
    recent: true,
    starred: true,
    trash: true,
  })
  updateAppSettings = new BehaviorSubject<any>(false)
  updateUserPermission = new BehaviorSubject<boolean>(false)
  updateUserInfo = new BehaviorSubject<boolean>(false)
  updateUserSettings = new BehaviorSubject<boolean>(false)
  showLoader = new BehaviorSubject<boolean>(false)
  showFilter = new BehaviorSubject<boolean>(false)

  resumableUploadQueue = new BehaviorSubject<any>({})
  uploadContainer = new BehaviorSubject<any>(null)
  uploadFolder = new BehaviorSubject<any>(null)
  trashFolderInfo = new BehaviorSubject<boolean>(false)
  trashDocInfo = new BehaviorSubject<boolean>(false)
  renameNode = new BehaviorSubject<any>(null)
  deleteNode = new BehaviorSubject<number[]>([])
  selectedIndex = new BehaviorSubject<any>(null)
  selectedFolderTreeId = new BehaviorSubject<any>(null)
  teamName = new BehaviorSubject<string>('')
  isTeamUrl = new BehaviorSubject<boolean>(false)
  teamAllowedSpace = new BehaviorSubject<boolean>(true)
  themeName = new BehaviorSubject<string>('default')
  isReviewer = new BehaviorSubject<boolean>(false)
  currentMembership = new BehaviorSubject<object>({})
  cancelDownload = new BehaviorSubject<boolean>(false)
  showCancelDownload = new BehaviorSubject<any>({})
  isAuthenticatedYoutube = new BehaviorSubject<boolean>(false)
  channelName = new BehaviorSubject<any>(null)
  isConnected = new BehaviorSubject<any>(true)
  isLoader = new BehaviorSubject<any>(true)
  reviewerUserID = new BehaviorSubject<any>(0)
  folderInfo = new BehaviorSubject<any>({})
  isMobile = new BehaviorSubject<Boolean>(false)
  isMobileAndTablet = new BehaviorSubject<Boolean>(false)
  isFileTargetNameShow = new BehaviorSubject<boolean>(false)
  dropTargetInfo = new BehaviorSubject<any>({})
  isShowFileDiv = new BehaviorSubject<boolean>(false)
  isAuthenticatedBox = new BehaviorSubject<Boolean>(false)
  boxFolderBCObj = new BehaviorSubject<any>([])
  boxFolderObj = new BehaviorSubject<any>([])
  boxFileObj = new BehaviorSubject<any>([])
  progressBarBox = new BehaviorSubject<any>(0)
  boxAccessTokenShare = new BehaviorSubject<any>('')
  canUploadToSharedWorkspace = new BehaviorSubject<boolean>(true)
  remainingSpace = new BehaviorSubject<any>(0)
  isFolderDragging = new BehaviorSubject<boolean>(false)
  moveNode = new BehaviorSubject<number[]>([])
  parentId = new BehaviorSubject<any>('')
  uploadType = new BehaviorSubject<any>('')
  teamMemberUsed = new BehaviorSubject<any>({})
  newSelectedFolder = new BehaviorSubject<any>({})
  workflowUsed = new BehaviorSubject<any>({})
  OktaInfo = new BehaviorSubject<any>([])
  menus = new BehaviorSubject<any>([])
  selectedMenu = new BehaviorSubject<any>([])
  isAuthenticatedVimeo = new BehaviorSubject<boolean>(false)
  constructor() {}

  setSelectedIndexOftreeView(Index: any) {
    this.selectedIndex.next(Index)
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
