export const getMenuData: any[] = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'My Documents',
    key: '__dashboard',
    url: '/dashboard/documents',
    icon: 'fe fe-folder',
    children: true,
  },
  {
    title: 'Dropbox',
    key: '__dropbox',
    url: '/dashboard/dropbox',
    icon: 'fa fa-dropbox',
  },
  {
    title: 'Vimeo',
    key: '__vimeo',
    url: '/dashboard/vimeo',
    icon: 'fa fa-vimeo',
  },
  {
    title: 'Box',
    key: '__box',
    url: '/dashboard/box',
    icon: 'fa fa-cubes',
  },
  {
    title: 'YouTube',
    key: '__youtube',
    url: '/dashboard/youtube',
    icon: 'fa fa-youtube',
  },
  {
    title: 'Shared With Me',
    key: '_Shared',
    url: '/dashboard/shared',
    icon: 'fe fe-user-plus',
  },
  {
    title: 'Recent',
    key: '__recent',
    url: '/dashboard/recent',
    icon: 'fe fe-clock',
  },
  {
    title: 'Starred',
    key: '__starred',
    url: '/dashboard/starred',
    icon: 'fe fe-star',
  },
  {
    title: 'Trash',
    key: '__trash',
    url: '/dashboard/trash',
    icon: 'fe fe-trash',
    notForTeam: true,
  },
  {
    title: 'Status',
    key: '__status',
    url: '/dashboard/production-status-management',
    icon: 'fe fe-pocket',
    notForTeam: true,
  },

  {
    title: 'Team',
    key: '__team',
    url: '/dashboard/team',
    icon: 'fe fe-users',
    notForTeam: true,
  },

  {
    title: 'Mail Settings',
    key: '__mailTemplates',
    url: '/dashboard/email-management',
    icon: 'fe fe-mail',
    notForTeam: false,
  },
  {
    title: 'Whitelabel',
    key: 'Whitelabel',
    url: '/dashboard/setting',
    icon: 'fe fe-zap',
    notForTeam: true,
  },
  {
    title: 'Workflow',
    key: 'Workflow',
    url: '/workflow/list',
    icon: 'fe fe-zap',
    notForTeam: true,
  },
  {
    title: 'Support / Feedback',
    key: 'Support',
    url: '/dashboard/support',
    icon: 'fe fe-message-circle',
  },

  // {
  //   title: 'Storage',
  //   key: '__support',
  //   url: 'Storage',
  //   icon: 'fe fe-home',
  // },
  // {
  //   title: 'Manage Subscription',

  //   key: '__support',
  //   url: '/dashboard/support',
  //   icon: 'fe fe-home',
  // },

  // VB:REPLACE-END:MENU-CONFIG
]
