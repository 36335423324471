<div class="d-flex flex-wrap justify-content-between pt-4 pb-2 pl-4 pr-4 title w-100">
  <div class="heading-group d-flex ml-md-2 w-100" (click)="clickOutsideCard()">
    <span class="heading" *ngIf="currentUrl === '/dashboard/documents' || currentUrl.indexOf('/dashboard/documents')>-1"
      (click)="this._dataService.dashboardDocument()">My Documents </span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/vimeo'"
      (click)="this._dataService.accessVimeo()">Vimeo </span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/youtube'"
      (click)="this._dataService.youtubeAccess()">YouTube {{(channelName!=null ? ' - ' +channelName : '')}}</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/box'" (click)="this._dataService.accessBox('',0,0)"> Box
    </span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/shared'" (click)="this._dataService.sharedWithMe()">Shared
      With Me</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/support'">Support</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/recent'"
      (click)="this._dataService.getRecent()">Recent</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/starred'"
      (click)="this._dataService.starredDocument()">Starred</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/trash'"
      (click)="this._dataService.trashDocument()">Trash</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/user-details'">User Details</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/edit-profile'">Edit Profile</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/change-password'">Change Password</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/setting'">Whitelabel</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/production-status-management'">Status</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/team'">Team</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/email-management/email'">Mail Settings</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/dropbox'"
      (click)="this._dataService.accessDropBox('', '');">Dropbox</span>
    <span class="heading" *ngIf="currentUrl === '/dashboard/integration'">Integration</span>
    <span class="heading" *ngIf="currentUrl === '/workflow/list'">Workflow</span>
    <span class="folder-breadcrumb" *ngIf="!isMobileView">
      <ul *ngIf="currentUrl != '/dashboard/dropbox' && currentUrl != '/dashboard/box' && currentUrl.indexOf('/workflow/') == -1">
        <li *ngFor="let dir of folderBCObj;let i=index;"><span class="arrow" *ngIf="dir.folderId"></span><a nz-tooltip
            nzTooltipTitle="{{!isMobileView ? dir.folderName : ''}}"
            (click)="this._dataService.getChildrenBC(dir.folderId,dir.folderName,dir.userId, dir);">{{dir.folderName}}</a>
        </li>
      </ul>
      <!-- <ul *ngIf="currentUrl != '/dashboard/dropbox'">
        <li *ngFor="let dir of searchItemPathArr;let i=index;">
           <span class="fa fa-angle-right"> </span>
          <a (click)="this._dataService.getChildrenBC(dir.folderId,dir.folderName,dir.userId, dir);">{{dir.folderName}}</a>
        </li>
      </ul> -->

      <ul *ngIf="currentUrl == '/dashboard/dropbox'">
        <li *ngFor="let dir of dropBoxFolderBCObj;"> <span class="arrow"> </span>
          <a (click)="this._dataService.accessDropBox(dir.folderPath, dir.id);">{{dir.folderName}}</a>
        </li>
      </ul>
      <ul *ngIf="currentUrl == '/dashboard/box'">
        <li *ngFor="let dir of boxFolderBCObj;"> <span class="arrow"> </span>
          <a (click)="this._dataService.accessBox(dir.folderPath,dir.id,dir.mainId);">{{dir.folderName}}</a>
        </li>
      </ul>
      <ul *ngIf="currentUrl == '/dashboard/youtube'">
        <li *ngFor="let dir of youTubeFolderBCObj;"> <span class="arrow"> </span>
          <a (click)="this._dataService.getYouTubeVideoByPlayListId(dir.folderId);">{{dir.folderName}}</a>
        </li>
      </ul>
      <ul *ngIf="currentUrl == '/dashboard/vimeo'">
        <li *ngFor="let dir of vimeoFolderBCObj;"> <span class="arrow"> </span>
          <a (click)="this._dataService.getVimeoFilesFromFolder(dir.folderId);">{{dir.folderName}}</a>
        </li>
      </ul>

    </span>
  </div>
  <div class="bc-right-icon text-right pr-3"
    *ngIf="currentUrl != '/dashboard/production-status-management' && currentUrl != '/dashboard/team' && currentUrl != '/dashboard/email-management/email' && currentUrl.indexOf('/workflow/') == -1">
    <span class="d-inline-flex align-item-center"
      *ngIf="(currentUrl != '/dashboard/support') && (currentUrl != '/dashboard/user-details') && (currentUrl != '/dashboard/edit-profile') && (currentUrl != '/dashboard/change-password') && (currentUrl != '/dashboard/setting') && currentUrl != '/dashboard/integration' && currentUrl.indexOf('/workflow/') == -1">

      <span 
        *ngIf="folderObj?.length != 0 || docInfo?.length != 0 || dropBoxFileObj?.length !=0 || dropBoxFolderObj?.length !=0 || youTubeFileObj?.length != 0 || boxFileObj?.length !=0 || boxFolderObj?.length !=0 || vimeoFileObj?.length != 0">
        <ng-container *ngIf="currentSelectedItem">

          <span class="quick-share-wrapper p-2 cursor-pointer" (click)="openWorkflow()" nz-tooltip
            *ngIf="currentPage!='trash' && currentPage!='dropbox'  && currentPage!='box'  && !sharedStatus && !isReviewer && !isTeamMember && currentSelectedItem =='Folder'"
            nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Apply Workflow' : ''}}" nzTooltipPlacement="bottom">
            <!-- <img  class="icon" src="assets/workflow.svg"> -->
            <img *ngIf="this.settings.theme == 'default'" class="workflow-icon " src="assets/workflow.svg">
            <img *ngIf="this.settings.theme != 'default'" class="workflow-icon " src="assets/workflowDark.svg">
          </span>

          <span class="quick-share-wrapper p-2 cursor-pointer" (click)="quickShare($event)" nz-tooltip
            nzTooltipTitle="{{!isMobileView ? 'Share' : ''}}" nzTooltipPlacement="bottom"
            *ngIf="(currentPage=='dropbox' || currentPage=='box' ) && !sharedStatus && currentSelectedItem == 'Document' && !isReviewer">
            <i class="fe fe-share-2"></i>
          </span>

          <span style="position:relative; top:1px" class="quick-share-wrapper p-2 cursor-pointer"
            (click)="quickShare($event)"
            *ngIf="currentPage!='trash' && currentPage!='dropbox'  && currentPage!='box'  && !sharedStatus && !isReviewer && !isTeamMember"
            nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Share' : ''}}" nzTooltipPlacement="bottom">
            <i class="fe fe-share-2"></i>
          </span>

          <span style="position:relative; top:1px" class="quick-preview-wrapper p-2 cursor-pointer"
            (click)="openPreview()"
            *ngIf="(currentPage!='trash' && currentSelectedItem == 'Document') && selectedDocumentArr.length == 1"
            nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Preview' : ''}}" nzTooltipPlacement="bottom">
            <i class="fe fe-eye font-size-16"></i>
          </span>

          <span class="quick-preview-wrapper p-2 cursor-pointer" (click)="removeStar($event); starBtn = false"
            *ngIf="(starBtn) && currentSelectedItem == 'Document' && sharedStatus" nz-tooltip
            nzTooltipTitle="{{!isMobileView ? 'Remove from starred' : ''}}" nzTooltipPlacement="bottom">
            <i class="fe fe-star-fill shareme"></i>
            <!-- <img height="16px" src="../../../../assets/unstar.svg" class="mr-3"> -->
          </span>

          <span class="quick-preview-wrapper p-2 mr-1 cursor-pointer" (click)="addStar($event); starBtn = true"
            *ngIf="(!starBtn) && currentSelectedItem == 'Document' && sharedStatus" nz-tooltip
            nzTooltipTitle="{{!isMobileView ? 'Add to starred' : ''}}" nzTooltipPlacement="bottom">
            <i class="fe fe-star"></i>

          </span>

          <span class="quick-delete-wrapper p-2 cursor-pointer" (click)="deleteFile($event)"
            *ngIf="currentPage=='trash'" nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Delete Forever' : ''}}"
            nzTooltipPlacement="bottom">
            <i class="fe fe-trash-2"></i>
          </span>

          <span class="quick-delete-wrapper p-2 cursor-pointer" (click)="trashFile($event)"
            *ngIf="(currentPage=='dropbox'  || currentPage=='box') && !sharedStatus && currentSelectedItem == 'Document'"
            nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Remove' : ''}}" nzTooltipPlacement="bottom">
            <i class="fe fe-trash"></i>
          </span>

          <span class="quick-delete-wrapper py-2 pl-2 cursor-pointer"
            [ngClass]="currentSelectedItem == 'Document' ? 'pr-0':'pr-2'" (click)="trashFile($event)"
            *ngIf="currentPage!='trash' && currentPage!='dropbox' && currentPage!='box' && !sharedStatus && !isReviewer && !isTeamMember"
            nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Remove' : ''}}" nzTooltipPlacement="bottom">
            <i class="fe fe-trash"></i>
          </span>


          <span class="quick-delete-wrapper p-2 cursor-pointer" (click)="rename($event)" nz-tooltip
            nzTooltipTitle="{{!isMobileView ? 'Rename' : ''}}" nzTooltipPlacement="bottom"
            *ngIf="(currentPage!='trash' && currentSelectedItem != 'Document' && selectedFolderArr.length<2 && currentPage!='dropbox' && currentPage!='box' && !sharedStatus && !isTeamMember)">
            <i class="fe fe-edit-2 mr-1"></i>
          </span>

          <span class="quick-delete-wrapper p-2 cursor-pointer" nz-tooltip
            nzTooltipTitle="{{!isMobileView ? 'Restore' : ''}}" nzTooltipPlacement="bottom"
            (click)="restoreFile($event)" *ngIf="currentPage=='trash'">
            <i class="fe fe-rotate-ccw"></i>
          </span>

          <span nz-dropdown nzTrigger="click" nzPlacement="bottomCenter" [nzDropdownMenu]="dropdownMenu"
            class="p-2 cursor-pointer" nz-tooltip nzTooltipTitle="{{!isMobileView ? 'More actions' : ''}}"
            nzTooltipPlacement="bottom"
            *ngIf="currentSelectedItem == 'Document' && currentPage != 'trash' && !sharedStatus && !isReviewer">
            <i class="fe fe-more-vertical"></i>
          </span>

          <nz-dropdown-menu #dropdownMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="openWorkflow()" *ngIf="!sharedStatus ">
                <img  class="workflow-icon" src="assets/workflow.svg">
                <span style="margin-left: 8px;">Apply Workflow </span>
              </li>
              <li (click)="rename()" nz-menu-item *ngIf="(currentPage!='trash' && selectedDocumentArr.length == 1)">
                <i class="fe fe-edit-2 mr-3"></i>Rename
              </li>
              <li (click)="addStar($event)" nz-menu-item
                *ngIf="(currentPage!='trash')&&(!starBtn) && currentSelectedItem == 'Document'">
                <i class="fe fe-star mr-3"></i>Add to Starred
              </li>
              <li (click)="removeStar($event)" nz-menu-item
                *ngIf="(currentPage!='trash')&&(starBtn) && currentSelectedItem == 'Document'">
                <i class="fe fe-star-fill bd mr-3"></i>Remove from Starred
              </li>
              <li nz-menu-item (click)="updateVersion();imgFileInput.click();"
                *ngIf="(currentPage!='trash' && currentSelectedItem == 'Document' && currentPage!='dropbox' && currentPage!='box') &&  selectedDocumentArr.length == 1 && fileUploadSource!='Dropbox' && selectedDocumentArr[0].documentContentType!='webproof' && selectedDocumentArr[0].documentContentType!='weblink' && fileUploadSource!='youtube' && isDropboxFile == false && fileUploadSource!='youtube' && currentPage!='vimeo'">
                <i class="fe fe-upload-cloud mr-3"></i>Update Version
                <input hidden type="file" #imgFileInput ng2FileSelect [uploader]="uploaderVersion" />
              </li>
              <li nz-menu-item (click)="incrementVersionElement()"
                *ngIf="(currentPage!='trash' && currentPage!='dropbox' && currentPage!='box') &&  selectedDocumentArr.length == 1 && fileUploadSource!='Dropbox' && (selectedDocumentArr[0].documentContentType=='webproof' || selectedDocumentArr[0].documentContentType=='weblink' || fileUploadSource=='youtube' || isDropboxFile == false) && fileUploadSource!='youtube'">
                <i class="fe fe-plus-square mr-3"></i>Increment Version
              </li>
              <li nz-menu-item
                *ngIf="(currentPage =='dashboard' && currentSelectedItem == 'Document' && fileUploadSource!='Dropbox' && fileUploadSource!='youtube'  && fileUploadSource!='box' && selectedDocumentArr[selectedDocumentArr.length-1]?.documentType != 'webproof')"
                (click)="downloadElement($event)">
                <ng-container [class.qr-disabled]="isReviewer  && selectedDocumentArr[0].download == 1">
                  <i class="fe fe-download mr-3"></i>Download
                </ng-container>
              </li>
              <li nz-menu-item (click)="versionInfo()"
                *ngIf="(currentPage!='trash' && currentSelectedItem == 'Document' && currentPage!='dropbox' && currentPage!='box' ) &&  selectedDocumentArr.length == 1 && fileUploadSource!='Dropbox' && fileUploadSource!='youtube' && currentPage!='vimeo'">
                <i class="fe fe-layers mr-3"></i>Manage Versions
              </li>
              <li (click)="setExpiry($event)" nz-menu-item
                *ngIf="currentPage!='trash' && currentSelectedItem == 'Document'">
                <i class="fe fe-clock mr-3"></i>Expiry & Reminders
              </li>

              <li nz-menu-item (click)="moveFile($event)"
                *ngIf="(currentPage!='trash' && currentSelectedItem == 'Document' && currentPage!='dropbox' && currentPage!='box' && fileUploadSource!='Dropbox' && fileUploadSource!='youtube' && currentPage!='vimeo' && (currentPage!='recent' || currentPage!='starred'))">
                <i class="fe fe-move mr-3"></i>Move File
              </li>
              <li nz-menu-item (click)="workFlowStatus()"
                *ngIf="(currentPage!='trash' && currentSelectedItem == 'Document')">
                <i class="fe fe-settings mr-3"></i>Change Status
              </li>
            </ul>
          </nz-dropdown-menu>

        </ng-container>

      </span>
      <span class="mobile-view-icon-container">
        <!-- <button class="empty-btn" mat-flat-button color="primary" (click)="emptyTrash($event)"
        >
          <i class="fe fe-trash-2"></i>
          <span> Empty </span>
        </button> -->
        <span>
          <button type="button" *ngIf="(currentPage=='trash') && (folderObj.length != 0 || docInfo.length != 0)"
            class="ant-btn empty-btn" (click)="emptyTrash($event)">
            Empty
          </button>
        </span>





        <span class="list-view-wrapper cursor-pointer p-2 ml-1" (click)="changeView()"
          (mouseenter)="listGridTooltip($event)" (mouseleave)="listGridTooltip($event)">
          <i class="fe fe-grid" *ngIf="listView" nzTooltipTitle="{{!isMobileView ? 'Grid View' : ''}}"
            nzTooltipPlacement="bottom" nz-button nz-tooltip></i>
          <i class="fe fe-list font-size-16" *ngIf="gridView" nzTooltipTitle="{{!isMobileView ? 'List View' : ''}}"
            nzTooltipPlacement="bottom" nz-button nz-tooltip></i>
        </span>

        <span class="view-details-wrapper p-2 cursor-pointer" (click)="onViewDetail()"
          [ngClass]="{'view-details-bg': viewBg}" (mouseenter)="viewDetailTooltip($event)"
          (mouseleave)="viewDetailTooltip($event)" nz-tooltip nzTooltipTitle="{{!isMobileView ? 'View Details' : ''}}"
          nzTooltipPlacement="bottom">
          <i class="fe fe-info font-size-16"></i>
        </span>


        <span class="view-details-wrapper p-2 cursor-pointer" (click)="this._dataService.refreshData()"
          [ngClass]="{'view-details-bg': viewBg}" (mouseenter)="viewDetailTooltip($event)"
          (mouseleave)="viewDetailTooltip($event)" nz-tooltip nzTooltipTitle="{{!isMobileView ? 'Refresh' : ''}}"
          nzTooltipPlacement="bottom">
          <i class="fe fe-refresh-cw"></i>
        </span>
      </span>
    </span>
  </div>
  <div *ngIf="isMobileView">
    <span class="folder-breadcrumb">
      <ul *ngIf="currentUrl != '/dashboard/dropbox' && currentUrl != '/dashboard/box'">
        <li *ngFor="let dir of folderBCObj;let i=index;"><span class="arrow"></span><a nz-tooltip
            nzTooltipTitle="{{!isMobileView ? dir.folderName : ''}}"
            (click)="this._dataService.getChildrenBC(dir.folderId,dir.folderName,dir.userId, dir);">{{dir.folderName}}</a>
        </li>
      </ul>
      <ul *ngIf="currentUrl == '/dashboard/dropbox'">
        <li *ngFor="let dir of dropBoxFolderBCObj;"> <span class="arrow"> </span>
          <a (click)="this._dataService.accessDropBox(dir.folderPath, dir.id);">{{dir.folderName}}</a>
        </li>
      </ul>
      <ul *ngIf="currentUrl == '/dashboard/box'">
        <li *ngFor="let dir of boxFolderBCObj;"> <span class="arrow"> </span>
          <a (click)="this._dataService.accessBox(dir.folderPath,dir.id,dir.mainId);">{{dir.folderName}}</a>
        </li>
      </ul>
    </span>
  </div>
</div>