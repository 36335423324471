import { Component, OnInit } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { filter } from 'rxjs/operators'
import { transition, trigger, style, animate } from '@angular/animations'
import * as _ from 'lodash'
import { select, Store } from '@ngrx/store'
import { MenuService } from 'src/app/services/menu'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'
import { environment } from 'src/environments/environment'
import { GlobalService } from 'src/app/services/common/global.service'
import { AuthService } from 'src/app/services/common/auth.service'
import { CreateFolderComponent } from 'src/app/pages/dashboard/modal-components/create-folder/create-folder.component'
import { NzModalService } from 'ng-zorro-antd/modal'
import { CreateWebproofComponent } from 'src/app/pages/dashboard/modal-components/create-webproof/create-webproof.component'
import { DocumentService } from 'src/app/services/document.service'
import { RemoveFileComponent } from 'src/app/pages/dashboard/modal-components/remove-file/remove-file.component'
import { FileUploaderOptions } from 'ng2-file-upload'
import { UploadService } from 'src/app/services/upload.service'
import { DataService } from 'src/app/services/data.service'
import { ConfirmationDialogComponent } from 'src/app/pages/confirmationDialogModule/confirmation-dialog/confirmation-dialog.component'
import { PouchDataService } from 'src/app/services/pouch-data.service'
import { FolderService } from 'src/app/services/folder.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'

@Component({
  selector: 'qr-flyout-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
  animations: [
    trigger('flyoutAnimation', [
      transition(':enter', [
        style({ transform: 'translate3d(0, calc(-50% + 40px), 0)' }),
        animate('100ms ease-in-out', style({ transform: 'translate3d(0, calc(-50% + 20px), 0)' })),
      ]),
    ]),
  ],
})
export class FlyoutMenuLeftComponent implements OnInit {
  logo: string
  version: string
  description: string
  menuData: any = []
  isMobileView: boolean
  isMobileMenuOpen: boolean
  isMenuCollapsed: boolean
  isMenuUnfixed: boolean
  isMenuShadow: boolean
  isSidebarOpen: boolean
  flyoutMenuType: string
  menuColor: string
  flyoutMenuColor: string
  menuLayoutType: string
  isAuthenticatedDropBox: boolean
  activeSubmenu: string = ''
  activeItem: string = ''
  renderedFlyoutItems: object = {}
  flyoutTimers: object = {}
  flyoutActive: boolean = false
  objectKeys = Object.keys
  apiUrl = environment.apiUrl
  src: string
  userSetting: any = {}
  isCustomUrlOwner: boolean
  customUrlLogo: boolean
  isCustomUrl: boolean

  hostName: string = window.location.host
  userPermission: any
  dynamicDarkLogoNumber: number
  dynamicLogoNumber: number
  darkLogo: string = ''
  currentPage: string
  currentUser: any
  selectedFolderObj: any
  uploader
  darkSrc: string
  trashDocInfo = false
  trashFolderInfo = false
  isFolderId: number
  isTeamUrl: boolean
  customAppSetting: any
  teamAllowedSpace: boolean
  isReviewer: boolean
  isAuthenticatedYoutube: boolean = false
  isAuthenticatedBox: boolean = false
  isAuthenticatedVimeo: boolean = false
  currentUrl: string = window.location.origin
  loginType: string = null
  constructor(
    private menuService: MenuService,
    private store: Store<any>,
    private router: Router,
    private globalService: GlobalService,
    private _authService: AuthService,
    private modalService: NzModalService,
    private _docService: DocumentService,
    private _uploadService: UploadService,
    private _dataService: DataService,
    private _pouchDataService: PouchDataService,
    private _folderService: FolderService,
    private notification: NzNotificationService,
  ) {}

  ngOnInit(): void {
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.logo = state.logo
      this.version = state.version
      this.description = state.description
      this.isMobileView = state.isMobileView
      this.isMobileMenuOpen = state.isMobileMenuOpen
      this.isMenuCollapsed = state.isMenuCollapsed
      this.isMenuUnfixed = state.isMenuUnfixed
      this.isMenuShadow = state.isMenuShadow
      this.flyoutMenuType = state.flyoutMenuType
      this.menuColor = state.menuColor
      this.flyoutMenuColor = state.flyoutMenuColor
      this.menuLayoutType = state.menuLayoutType
      this.isSidebarOpen = state.isSidebarOpen
      this.flyoutActive =
        (state.flyoutMenuType === 'flyout' ||
          state.flyoutMenuType === 'compact' ||
          state.isMenuCollapsed) &&
        !state.isMobileView
    })
    this.setActiveItems(this.router.url)
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.setActiveItems(event.url ? event.url : null)
      })
    this.globalService.isCustomUrl.subscribe(customUrl => {
      this.isCustomUrl = customUrl
    })
    this.globalService.isTeamUrl.subscribe(isTeamUrl => {
      this.isTeamUrl = isTeamUrl
    })
    this.globalService.teamAllowedSpace.subscribe(
      teamAllowedSpace => (this.teamAllowedSpace = teamAllowedSpace),
    )
    this.globalService.isReviewer.subscribe(isReviewer => (this.isReviewer = isReviewer))
    this.globalService.customAppSetting.subscribe(customAppSetting => {
      if (Object.keys(customAppSetting).length > 0) {
        this.customAppSetting = customAppSetting
        this.src = customAppSetting.logo
        this.darkSrc = customAppSetting.darkLogo
        if (customAppSetting.appUrlType != 'default' && customAppSetting.loginType == 'owner') {
          this.loginType == 'owner'
        }
      }
    })

    this.globalService.isCustomUrlOwner.subscribe(isCustomUrlOwner => {
      this.isCustomUrlOwner = isCustomUrlOwner
    })

    if (!this.isCustomUrl) {
      this.customUrlLogo = false
    }

    this.globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    this.globalService.isAuthenticatedDropBox.subscribe(
      isAuthenticatedDropBox => (this.isAuthenticatedDropBox = isAuthenticatedDropBox),
    )
    this.globalService.userPermission.subscribe(
      userPermission => (this.userPermission = userPermission),
    )

    this.globalService.isAuthenticatedYoutube.subscribe(isAuthenticatedYoutube => {
      this.isAuthenticatedYoutube = isAuthenticatedYoutube
    })

    this.globalService.isAuthenticatedBox.subscribe((isAuthenticatedBox: boolean) => {
      this.isAuthenticatedBox = isAuthenticatedBox
    })
    this.globalService.isAuthenticatedVimeo.subscribe((isAuthenticatedVimeo: boolean) => {
      this.isAuthenticatedVimeo = isAuthenticatedVimeo
    })

    // this.uploader = this._uploadService.uploader
    // const uo: FileUploaderOptions = {}
    // uo.headers = [{ name: 'Authorization', value: `AUTH ${this._authService.getToken()}` }] /// will changed
    // this.uploader.setOptions(uo)
    // const userId = localStorage.getItem('userId')
    // this._authService.getUserPermission().subscribe(servicesData => {
    //   this.userPermission = servicesData
    //   this.globalService.userPermission.next(servicesData)
    // })

    // if (this.customUrlLogo || this.isTeamUrl) {
    //   this.src = (this.customAppSetting.logo) ? this.customAppSetting.logo : environment.apiUrl + '/logo/0/0/' + this.hostName
    //   this.darkSrc = (this.customAppSetting.darkLogo) ? this.customAppSetting.darkLogo : environment.apiUrl + '/logo-dark/0/0' + this.hostName
    // }

    this.globalService.userInfo.subscribe(userInfo => (this.currentUser = userInfo))

    this.globalService.userSetting.subscribe(userSetting => {
      if (userSetting && Object.keys(userSetting).length > 0) {
        this.darkLogo = userSetting.setting_obj.dark_logo
        this.userSetting = userSetting
        this.generateRandomNumber()
      } else {
        // this.globalService.updateUserSettings.next(true)
      }
    })

    this.globalService.trashDocInfo.subscribe(docInfo => (this.trashDocInfo = docInfo))
    this.globalService.trashFolderInfo.subscribe(folderInfo => (this.trashFolderInfo = folderInfo))

    this.globalService.selectedFolderObj.subscribe(
      selectedFolderObj => (this.selectedFolderObj = selectedFolderObj),
    )
    this.generateRandomNumber()
  }

  generateRandomNumber() {
    this.dynamicLogoNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1
    this.dynamicDarkLogoNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1
  }

  errorHandler(event) {
    // this.src = (this.customAppSetting.logo) ? this.customAppSetting.logo : environment.apiUrl + '/other-logo/qr'
  }

  errorDarkImageHandler(event) {
    // this.darkSrc = (this.customAppSetting.darkLogo) ? this.customAppSetting.darkLogo : environment.apiUrl + '/other-logo/qr-dark'
  }

  toggleMobileMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMobileMenuOpen: !this.isMobileMenuOpen,
      }),
    )
  }

  toggleMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: !this.isMenuCollapsed,
      }),
    )
  }

  toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen,
      }),
    )
  }

  handleSubmenuClick(key: string) {
    const currentKey = this.activeSubmenu
    if (this.flyoutActive) {
      return
    }
    this.activeSubmenu = currentKey === key ? '' : key
  }

  setActiveItems(pathname) {
    const menuData = this.menuData
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const activeItem = _.find(flattenItems(menuData, 'children'), ['url', pathname]) || {}
    const activeSubmenu = menuData.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map(child => {
          if (child.key === activeItem.key) {
            key = parent
          }
          return ''
        })
      }
      return key
    })

    this.activeItem = activeItem.key
    this.activeSubmenu = activeSubmenu.key
  }

  handleFlyoutOver(event, key, items) {
    if (this.flyoutActive) {
      clearInterval(this.flyoutTimers[key])
      const item = event.currentTarget
      const itemDimensions = item.getBoundingClientRect()
      this.renderedFlyoutItems = {
        ...this.renderedFlyoutItems,
        [key]: {
          key,
          itemDimensions,
          items,
        },
      }
    }
  }

  isWorksapce() {
    if (localStorage.getItem('finder-selected-folder')) {
      this.isFolderId = parseInt(localStorage.getItem('finder-selected-folder'))
    } else {
      this.isFolderId = 0
    }
  }

  handleFlyoutOut(key) {
    if (this.flyoutActive) {
      this.flyoutTimers[key] = setTimeout(() => {
        const updatedFlyoutItems = Object.assign({}, this.renderedFlyoutItems)
        delete updatedFlyoutItems[key]
        this.renderedFlyoutItems = {
          ...updatedFlyoutItems,
        }
      }, 100)
    }
  }

  handleFlyoutContainerOver(key) {
    clearInterval(this.flyoutTimers[key])
  }

  createFolder(type: string, isWorkspace) {
    debugger
    if (
      ((this.currentPage == 'dashboard' && this.teamAllowedSpace) ||
        (this.currentPage == 'dashboard' &&
          this.selectedFolderObj.folderId > 0 &&
          this.teamAllowedSpace)) &&
      !this.isReviewer
    ) {
      const folderId = this.selectedFolderObj.folderId
      const addWorkspaceFolderRef = this.modalService.create({
        nzTitle: type === 'workspace' ? 'New Workspace' : 'New Folder',
        nzContent: CreateFolderComponent,
        nzComponentParams: {
          data: {
            name: type === 'workspace' ? 'Untitled Workspace' : 'Untitled Folder',
            type: type,
            folderId: folderId,
            workspaceId: this.selectedFolderObj.workspaceId,
            isWorkspace: isWorkspace,
          },
        },
        nzMaskClosable: false,
        nzClosable: false,
        nzFooter: null,
        nzWidth: 500,
      })
    } else {
      // this._dataService.showAlertMessage(
      //   'Permission required',
      //   'file',
      //   'You do not have permission to create folder here, Please contact your administrator.',
      // )
    }
  }

  accessWorkspace(checkMembership) {
    if (
      checkMembership &&
      (this.currentPage !== 'dashboard' ||
        (this.currentPage === 'dashboard' && this.selectedFolderObj.folderId === 0))
    ) {
      return false
    } else {
      return true
    }
  }

  createWebproof() {
    if (
      ((this.currentPage == 'dashboard' && this.teamAllowedSpace) ||
        (this.currentPage == 'dashboard' && this.selectedFolderObj.folderId > 0)) &&
      !this.isReviewer
    ) {
      const currentUserId =
        localStorage.getItem('workspaceUserId') != null &&
        localStorage.getItem('workspaceUserId') != undefined
          ? parseInt(localStorage.getItem('workspaceUserId'))
          : this.currentUser.userId
      const docObj = { userId: currentUserId }
      this._docService.getWeblinkCount(docObj).subscribe(res => {
        const count = res.count
        const limitBreached = res.limitBreached

        if (limitBreached) {
          const removeWorkspaceFolderRef = this.modalService.create({
            nzTitle: 'New Website',
            nzFooter: null,
            nzMaskClosable: false,
            nzContent: RemoveFileComponent,
            nzComponentParams: {
              data: {
                title: 'Limit reached',
                content: `You have reached the maximum limit of ${count} websites allowed in your account. Delete trashed websites or the ones in 'My Documents' to free up website slots.`,
              },
            },
          })
        } else {
          const createWebproof = this.modalService.create({
            nzTitle: 'New Website',
            nzFooter: null,
            nzContent: CreateWebproofComponent,
            nzMaskClosable: false,
            nzComponentParams: {
              data: {
                value: 'https://',
              },
            },
          })
          // this.dialog.open(CreateWebproofComponent, {
          //   width: '500px',
          //   maxWidth: '95vw',
          //   panelClass: 'webLinks',
          //   disableClose: true,
          //   data: { value: 'http://' }
          // })
        }
      })
    } else {
      // this._dataService.showAlertMessage(
      //   'Permission required',
      //   'file',
      //   'You do not have permission to create webproof here, Please contact your administrator.',
      // )
    }
  }

  clickOutsideCard() {
    this._dataService.resetAllObservable()
  }

  emptyTrash(e): void {
    e.stopPropagation()
    const confirmationDialog = this.modalService.create({
      nzTitle: 'Empty Trash',
      nzContent: ConfirmationDialogComponent,
      nzComponentParams: {
        message:
          'This is an irreversible action. Are you sure you want to delete the documents permanently?',
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
    })
    confirmationDialog.afterClose.subscribe(result => {
      if (result) {
        this.emptyFile(e)
      }
    })
  }

  emptyFile(e) {
    this.globalService.showLoader.next(true)
    this._docService.emptyTrash().subscribe(
      res => {
        this.globalService.showLoader.next(false)
        this._pouchDataService.emptyFileData('trash')
        if (this.currentPage === 'trash') {
          this.globalService.docInfo.next([])
        }
        this.globalService.trashDocInfo.next(false)
        this._dataService.storagedocs()
      },
      err => {
        console.log('err', err)
        this.globalService.showLoader.next(false)
      },
    )
    this._folderService.emptyTrash().subscribe(
      res => {
        this.globalService.showLoader.next(false)
        this._pouchDataService.emptyData('trash')
        if (this.currentPage === 'trash') {
          this.globalService.folderObj.next([])
        }
        this.globalService.trashFolderInfo.next(false)
        this._dataService.storagedocs()
        // this.snackBar.open('Trash emptied.', '', { duration: 3000 });
        this.notification.create('success', null, 'Trash emptied.', { nzPlacement: 'bottomRight' })
      },
      err => {
        this.globalService.showLoader.next(false)
        this.notification.create('error', null, err.error.failed, { nzPlacement: 'bottomRight' })
      },
    )
  }

  async addFileToUploadQueue(e) {
    if (e.length > 0) {
      this.globalService.uploadContainer.next('sidebar-file')
      this.globalService.uploadFolder.next(this.selectedFolderObj)
      for (var i = 0; i < e.length; i++) {
        await this._uploadService.resumableUpload.addFile(e[i])
        await this.globalService.sleep(250)
      }
    }
  }
}
