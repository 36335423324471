import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { DocumentActivityLog, DocumentDetail } from '../Model/document'
import { GlobalService } from './common/global.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as base64 from 'base-64'
import { env } from 'process'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  }),
}
export interface Config {
  apiUrl: string
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private apiUrl = environment.apiUrl
  private _activeDocUrl = this.apiUrl + '/documents'
  private _recentUrl = this.apiUrl + '/recent'
  private _sharedWithMeUrl = this.apiUrl + '/shared-with-me'
  private _sharedUrl = this.apiUrl + '/shared'
  private _starredUrl = this.apiUrl + '/starred'
  private _trashedUrl = this.apiUrl + '/trashed'
  private _quickAccessDocUrl = this.apiUrl + '/quick-access'
  private _trashDocUrl = this.apiUrl + '/document/trash'
  private _restoreDocUrl = this.apiUrl + '/document/restore'
  private _renameDocUrl = this.apiUrl + '/document/rename'
  private _setExpiryUrl = this.apiUrl + '/document/setexpiry'
  private _addStarUrl = this.apiUrl + '/document/add-star'
  private _removeStarUrl = this.apiUrl + '/document/remove-star'
  private _shareDocUrl = this.apiUrl + '/document/share'
  private _deleteDocUrl = this.apiUrl + '/document/delete'
  private _deleteVersionUrl = this.apiUrl + '/document/delete-version'
  private _emptyDocUrl = this.apiUrl + '/document/empty'
  private _lastDocUrl = this.apiUrl + '/document/get-last-document'
  private _storageUrl = this.apiUrl + '/storage'
  private _docDetailUrl = this.apiUrl + '/document/document-detail'
  private _docVersionsUrl = this.apiUrl + '/document/document-versions'
  private _docLogActivityUrl = this.apiUrl + '/document/log'
  private _docSharedUser = this.apiUrl + '/document/shared-user'
  private _updateDocShareAction = this.apiUrl + '/document/change-share-action'
  private _deleteDocShare = this.apiUrl + '/document/delete-share'
  private _moveDocUrl = this.apiUrl + '/document/move'
  private _proofTokenUrl = this.apiUrl + '/document/proof-token'
  private _wfStatusUrl = this.apiUrl + '/wfstatus'
  private _changeWFStatusUrl = this.apiUrl + '/document/change-status'
  private _dropboxDocUrl = this.apiUrl + '/document/add-doc'
  private _dropboxDocsUrl = this.apiUrl + '/document/add-box-doc'
  private _updateAllDocShareAction = this.apiUrl + '/document/change-doc-share-action'
  private _allSharedUserUrl = this.apiUrl + '/document/get-all-shared-user'
  private _sharedProofTokenUrl = this.apiUrl + '/document/shared-token'
  private _verifyTokenUrl = this.apiUrl + '/verify-proof'
  private _activeDocWUrl = this.apiUrl + '/documents-w'
  private _proofTokenWUrl = this.apiUrl + '/document/proof-token-w'
  private _getDocumentDownloadUrl = this.apiUrl + '/document/doc-download-path/'
  private _getDocumentDownloadUrlS3 = this.apiUrl + '/document/doc-download-path-S3/'
  private _createWeblinkUrl = this.apiUrl + '/document/add-web-url'
  private _createYoutubeUrl = this.apiUrl + '/document/add-youtube-url'
  private _getUrlExists = this.apiUrl + '/document/get-url-exists'
  private _removeDropboxAccountUrl = this.apiUrl + '/remove-dropbox-account'
  private _updateDropboxVersionUrl = this.apiUrl + '/update-dropbox-version'
  private _incrementVersion = this.apiUrl + '/document/increment-web-version'
  private _weblinkCount = this.apiUrl + '/weblink-count'
  private _wfStatusListUrl = this.apiUrl + '/wfstatus-list'
  private _notificationListUrl = this.apiUrl + '/notification-list'
  private _docSettingUrl = this.apiUrl + '/document/doc-share-setting'
  private _insertNewDocument = this.apiUrl + '/insert-new-document'
  private _getVimeoRedirectUrl = this.apiUrl + '/vimeo-authorize-url'
  private _vimeoAccessToken = this.apiUrl + '/vimeo-access-token'
  private _disconnectApp = this.apiUrl + '/disconnect-app/'
  private _getChannelid = this.apiUrl + '/get-channel/'
  private _saveLinkedAppData = this.apiUrl + '/save-app-data'
  private _moveComment = this.apiUrl + '/move-comment'
  private _removeBoxAccountUrl = this.apiUrl + '/remove-box-account'
  private _boxAccessTokenUrl = this.apiUrl + '/token/box/'
  private _boxFolderUnlink = this.apiUrl + '/trash/document/box/'
  private _validateBoxUser = this.apiUrl + '/validate-token/'
  private youtubeApiKey = environment.youTubeAPIKey
  private youtubeClientId = environment.youTubeClientID
  private youtubeProjectId = environment.youTubeProjectId
  private youtubeClientSecret = environment.youTubeClientSecret
  private boxClientId = environment.boxClientId
  private boxClientSecret = environment.boxClientSecret;
  private boxRedirectUri = environment.boxRedirectUri;
  private boxURL = environment.boxURL;
  private boxWebHookUrk = this.apiUrl + "/webhook/box/"
  private _workflow = this.apiUrl + "/workFlow/workFlow-add"
  private _getWorkflowList = this.apiUrl + '/workFlow/workFlow-get'
  private _deleteWorkFlow = this.apiUrl + '/workFlow/workFlow-delete'
  private getWorkspaceOwnerStorage = this.apiUrl + "/get-workspace-owner-storage"
  private _checkFileExist = this.apiUrl + "/document/check-exists"
  private _getTotalTeamMember = this.apiUrl + "/team-count"
  private _applyApprovalWorkflow = this.apiUrl + "/workFlow/apply-approval-wf"
  private _excuteApprovalWorkflow = this.apiUrl + "/execute/approval-workflow"
  private _getworkflowIdBydocId =this.apiUrl + "/document/get-workflow";
  private _getworkflowIdByfolderId =this.apiUrl + "/document/get-workflow-folder";
  private _workflowCount = this.apiUrl + '/workFlow/workflow-count'
  private getOtherUserStorage = this.apiUrl + '/checkStorage'
  private _getworkflowDetails = this.apiUrl + '/workFlow/document'
  
  selectedFolderObj: any = {}
  currentVersion: Number = 1
  currentDocument: any = null
  currentPage: string
  currentUser: any
  customAppSetting: any

  constructor(private http: HttpClient, private _globalService: GlobalService) {
    this._globalService.selectedFolderObj.subscribe(
      selectedFolderObj => (this.selectedFolderObj = selectedFolderObj),
    )
    this._globalService.currentVersion.subscribe(
      currentVersion => (this.currentVersion = currentVersion),
    )
    this._globalService.currentDocument.subscribe(
      currentDocument => (this.currentDocument = currentDocument),
    )
    this._globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    this._globalService.userInfo.subscribe(userInfo => (this.currentUser = userInfo))
    this._globalService.customAppSetting.subscribe(
      customAppSetting => (this.customAppSetting = customAppSetting),
    )
  }

  activeDocuments(docObj) {
    if (this.selectedFolderObj.isWorkspace == 1) {
      localStorage.setItem('isWorkspace', '1')
      localStorage.setItem('workspaceId', this.selectedFolderObj.workspaceId)
      localStorage.setItem('workspaceUserId', this.selectedFolderObj.workspaceUserId)
    } else if (this.selectedFolderObj.isWorkspace == 0 && this.selectedFolderObj.workspaceId == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }
    let isWorkspace = parseInt(localStorage.getItem('isWorkspace'))
    docObj.isWorkspace = isWorkspace == 1 ? 1 : 0
    return this.http.post<any>(this._activeDocUrl, docObj, httpOptions)
  }

  recentDocuments(docObj) {
    return this.http.post<any>(this._recentUrl, docObj, httpOptions)
  }

  sharedWithMeDocuments(docObj) {
    return this.http.post<any>(this._sharedWithMeUrl, docObj, httpOptions)
  }

  sharedDocuments(docObj) {
    return this.http.post<any>(this._sharedUrl, docObj, httpOptions)
  }

  starredDocuments(docObj) {
    return this.http.post<any>(this._starredUrl, docObj, httpOptions)
  }

  trashedDocuments(docObj) {
    return this.http.post<any>(this._trashedUrl, docObj, httpOptions)
  }

  quickAccessDocuments() {
    return this.http.get<any>(this._quickAccessDocUrl, httpOptions)
  }

  trashDocument(docObj) {
    return this.http.patch<any>(this._trashDocUrl, docObj, httpOptions)
  }

  restoreDocument(docObj) {
    return this.http.patch<any>(this._restoreDocUrl, docObj, httpOptions)
  }

  renameDocument(docObj) {
    return this.http.post<any>(this._renameDocUrl, docObj, httpOptions)
  }

  setExpiryDocument(docObj) {
    return this.http.post<any>(this._setExpiryUrl, docObj, httpOptions)
  }

  addStarDocument(docObj) {
    return this.http.post<any>(this._addStarUrl, docObj, httpOptions)
  }

  removeStarDocument(docObj) {
    return this.http.post<any>(this._removeStarUrl, docObj, httpOptions)
  }

  shareDocument(docObj) {
    return this.http.post<any>(this._shareDocUrl, docObj, httpOptions)
  }

  documentShareSetting(docObj) {
    return this.http.post<any>(this._docSettingUrl, docObj, httpOptions)
  }

  deleteDocument(docObj) {
    return this.http.post<any>(this._deleteDocUrl, docObj, httpOptions)
  }

  deleteVersion(docObj) {
    return this.http.post<any>(this._deleteVersionUrl, docObj, httpOptions)
  }

  emptyTrash() {
    return this.http.delete<any>(this._emptyDocUrl, httpOptions)
  }

  getWeblinkCount(docObj) {
    return this.http.post<any>(this._weblinkCount, docObj, httpOptions)
  }

  lastDocument() {
    return this.http.get<any>(this._lastDocUrl, httpOptions)
  }

  storageInfo() {
    return this.http.get<any>(this._storageUrl, httpOptions)
  }

  documentDetail(docObj) {
    return this.http.post<DocumentDetail>(this._docDetailUrl, docObj, httpOptions)
  }

  documentVersions(docObj) {
    return this.http.post<any>(this._docVersionsUrl, docObj, httpOptions)
  }

  documentLogActivity(docObj) {
    return this.http.post<Array<DocumentActivityLog>>(this._docLogActivityUrl, docObj, httpOptions)
  }

  documentSharedUser(docObj) {
    return this.http.post<any>(this._docSharedUser, docObj, httpOptions)
  }
  updateDocumentShareAction(docObj) {
    return this.http.post<any>(this._updateDocShareAction, docObj, httpOptions)
  }

  deleteDocumentShare(docObj) {
    return this.http.post<any>(this._deleteDocShare, docObj, httpOptions)
  }

  moveDocument(docObj) {
    return this.http.patch<any>(this._moveDocUrl, docObj, httpOptions)
  }

  getProofToken(docObj) {
    return this.http.post<any>(this._proofTokenUrl, docObj, httpOptions)
  }

  getWFStatus(docObj) {
    return this.http.post<any>(this._wfStatusUrl, docObj, httpOptions)
  }

  changeWFStatus(docObj) {
    return this.http.patch<any>(this._changeWFStatusUrl, docObj, httpOptions)
  }

  addDropboxDocUrl(docObj) {
    return this.http.post<any>(this._dropboxDocUrl, docObj, httpOptions)
  }

  addDropboxDocsUrl(docObj) {
    return this.http.post<any>(this._dropboxDocsUrl, docObj, httpOptions)
  }

  updateAllDocumentShareAction(docObj) {
    return this.http.post<any>(this._updateAllDocShareAction, docObj, httpOptions)
  }

  getPath(url) {
    return this.http.get<any>(url, httpOptions)
  }

  getAllSharedUserUrl(isOwnerId, folderId, documentId) {
    return this.http.get<any>(
      this._allSharedUserUrl + '/' + isOwnerId + '/' + folderId + '/' + documentId,
      httpOptions,
    )
  }

  getSharedProofToken(docObj) {
    return this.http.post<any>(this._sharedProofTokenUrl, docObj, httpOptions)
  }

  getVerifyProofToken(token, email) {
    return this.http.get<any>(this._verifyTokenUrl + '/' + token + '/' + email, httpOptions)
  }

  activeDocumentsW(docObj) {
    return this.http.post<any>(this._activeDocWUrl, docObj, httpOptions)
  }

  getProofWToken(docObj) {
    // localStorage.setItem('isMultiproof', 'true')
    return this.http.post<any>(this._proofTokenWUrl, docObj, httpOptions)
  }

  createWeblink(weblinkObj) {
    return this.http.post<any>(this._createWeblinkUrl, weblinkObj, httpOptions)
  }

  createYoutube(youtubeObj) {
    return this.http.post<any>(this._createYoutubeUrl, youtubeObj, httpOptions)
  }

  getDocumentToDownload(docObj) {
    return this.http.get(
      this._getDocumentDownloadUrl +
      docObj.userId +
      '/' +
      docObj.documentId +
      '/' +
      docObj.versionId,
      {
        reportProgress: true,
        observe: 'response',
        responseType: 'blob',
      },
    )
    // console.log(this.subs)
    // let index = this.can.indexOf(element.documentId)
    // this.subs[index].unsubscribe()
    // subs: Subscription[] = [];
    // can = []
    // this.can.push(element.documentId)
    //     this.subs.push(this._docService.getDocumentToDownload(docObj).subscribe(async (data: any) => {
    //       if (data.type === HttpEventType.DownloadProgress) {
    //         const percentDone = Math.round(100 * data.loaded / data.total);
    //         this.manageLoader(
    //           'bar',
    //           element.documentId,
    //           1,
    //           percentDone + '%',
    //         )
    //         console.log(percentDone)
    //       }
    //       if (data.type === HttpEventType.Response) {
    //         //this.generateDownload(result.body);\
    //         console.log(data.headers)
    //         let filename = data.headers.get('Content-Disposition')
    //         filename = filename.split('filename=')[1].replace(/['"]/g, '')
    //         if (!filename) filename = element.documentFileName
    //         // Download the file
    //         saveAs(data.body, filename)
    //         this.manageLoader('bar', element.documentId, 0)
    //         // this.globalService.showCancelDownload.next(false)
    //         // this.docInfo[index].cancelDownload = false
    //       }

    //     }));
  }

  getDocumentToDownloadS3(docObj) {
    return this.http.get(
      this._getDocumentDownloadUrlS3 +
      '/' +
      docObj.userId +
      '/' +
      docObj.documentId +
      '/' +
      docObj.versionId,
      httpOptions,
    )
  }

  getUrlExists(docObj) {
    return this.http.post<any>(this._getUrlExists, docObj, httpOptions)
  }

  removeDropboxAccount(docObj) {
    return this.http.post<any>(this._removeDropboxAccountUrl, docObj, httpOptions)
  }

  removeBoxAccount(docObj) {
    return this.http.post<any>(this._removeBoxAccountUrl, docObj, httpOptions)
  }

  updateDropboxVersion(docObj) {
    return this.http.post<any>(this._updateDropboxVersionUrl, docObj, httpOptions)
  }

  incrementWeblinkVersion(docObj) {
    return this.http.post(this._incrementVersion, docObj, httpOptions)
  }

  wfStatusList(data = null) {
    data = data == null ? { order: 'asc', page: 1, sort: 'status_name' } : data
    return this.http.post(this._wfStatusListUrl, data, httpOptions)
  }

  notificationList(page) {
    return this.http.post(this._notificationListUrl, page, httpOptions)
  }

  vimeoUrl() {
    return this.http.get(this._getVimeoRedirectUrl, httpOptions)
  }
  vimeoAccessToken(token) {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+ token,
        'Accept': 'application/vnd.vimeo.*+json;version=3.4'
      }),
    }
    
    return this.http.get<any>(`https://api.vimeo.com/me`, httpHeaders)
  }

  getVimeoVideoList(): Observable<Object> {
    //  this.globalService.isLoading.next(true);
    let videoObject: any = ''
    videoObject = localStorage.getItem('vimeo')
    if (videoObject != '') {
      videoObject = JSON.parse(videoObject)
      let uriKey = videoObject.uri.replace('/users/', '')
      let raw = {
        containing_uri: '',
        direction: 'asc',
        filter: '',
        filter_category: '',
        filter_embeddable: '',
        filter_playable: '',
        filter_screen_recorded: '',
        include_team_content: '',
        page: '',
        per_page: '',
        query: '',
        sort: 'alphabetical',
        user_id: uriKey,
      }

      const headerDict = {
        Authorization: 'Bearer ' + videoObject.access_token,
        'Content-Type': 'application/vnd.vimeo.video+json',
      }
      const requestOptions = { headers: new HttpHeaders(headerDict), body: JSON.stringify(raw) }
      return this.http
        .get('https://api.vimeo.com/users/' + uriKey + '/videos', requestOptions)
        .pipe(
          map(res => {
            return res
          }),
        )
    } else {
      //this.globalService.isAuthenticatedVimeo.next(false);
      return null
    }
  }

  getVimeoFilesFromFolder(arg) {
    let param = arg
    let url = 'https://api.vimeo.com/'
    url = url + param + '/videos'
    let videoObject: any = ''
    videoObject = localStorage.getItem('vimeo')
    if (videoObject != '') {
      videoObject = JSON.parse(videoObject)
      let uriKey = videoObject.uri.replace('/users/', '')
      let raw = {
        containing_uri: '',
        direction: 'asc',
        filter: '',
        filter_category: '',
        filter_embeddable: '',
        filter_playable: '',
        filter_screen_recorded: '',
        include_team_content: '',
        page: '',
        per_page: '',
        query: '',
        sort: 'alphabetical',
        user_id: uriKey,
      }

      const headerDict = {
        Authorization: 'Bearer ' + videoObject.access_token,
        'Content-Type': 'application/vnd.vimeo.video+json',
      }
      const requestOptions = { headers: new HttpHeaders(headerDict), body: JSON.stringify(raw) }
      return this.http.get(url, requestOptions).pipe(
        map(res => {
          return res
        }),
      )
    }
  }

  getVimeoFolder() {
    let videoObject: any = ''
    videoObject = localStorage.getItem('vimeo')
    if (videoObject) {
      videoObject = JSON.parse(videoObject)
      let uriKey = videoObject?.uri.replace('/users/', '')
      let raw = {
        containing_uri: '',
        direction: 'asc',
        filter: '',
        filter_category: '',
        filter_embeddable: '',
        filter_playable: '',
        filter_screen_recorded: '',
        include_team_content: '',
        page: '',
        per_page: '',
        query: '',
        sort: 'alphabetical',
        user_id: uriKey,
      }

      const headerDict = {
        Authorization: 'Bearer ' + videoObject.access_token,
        'Content-Type': 'application/vnd.vimeo.video+json',
      }
      const requestOptions = { headers: new HttpHeaders(headerDict), body: JSON.stringify(raw) }
      return this.http.get('https://api.vimeo.com/users/'+uriKey+'/folders', requestOptions).pipe(
        map(res => {
          return res
        }),
      )
    }
  }
  disconnectApp(appString: any): Observable<any> {
    return this.http.get<any>(this._disconnectApp + appString, httpOptions)
  }

  getAceessToken(authToken: any): Observable<Object> {
    let tokenUrl = 'https://oauth2.googleapis.com/token'
    let authParams = {
      grant_type: 'refresh_token',
      refresh_token: authToken,
      client_id: this.youtubeClientId,
      client_secret: this.youtubeClientSecret,
      project_id: this.youtubeProjectId,
      scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
      auth_uri: 'https://accounts.google.com/o/oauth2/auth',
      token_uri: 'https://oauth2.googleapis.com/token',
      auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    }
    return this.http.post(tokenUrl, authParams).pipe(
      map(res => {
        return res
      }),
    )
  }

  getChannel(accessToken: any): Observable<Object> {
    const headerDict = { Authorization: 'Bearer ' + accessToken }
    const requestOptions = { headers: new HttpHeaders(headerDict) }
    let url =
      'https://youtube.googleapis.com/youtube/v3/channels?part=snippet&mine=true&key=' +
      this.youtubeApiKey
    return this.http.get(url, requestOptions).pipe(
      map(res => {
        return res
      }),
    )
  }

  getChannelId(userToken: any): Observable<any> {
    return this.http.get<any>(this._getChannelid + userToken, httpOptions)
  }

  getChannelVideos(channelId: any) {
    const headerDict = { Authorization: 'Bearer ' + localStorage.getItem('youtubeAccessToken') }
    const requestOptions = { headers: new HttpHeaders(headerDict) }
    let url = `https://www.googleapis.com/youtube/v3/search?key=${this.youtubeApiKey}&channelId=${channelId}&order=date&part=snippet,id&type=video,id&maxResults=90`
    return this.http.get(url).pipe(
      map(res => {
        return res
      }),
    )
  }

  getYouTubePlayList(channelId: any) {
    let url = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=${channelId}&key=${this.youtubeApiKey}`
    return this.http.get(url).pipe(
      map(res => {
        return res
      }),
    )
  }

  getYouTubeVideoByPlayListId(payListId: any) {
    let url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails,status&playlistId=${payListId}&key=${this.youtubeApiKey}`
    return this.http.get(url).pipe(
      map(res => {
        return res
      }),
    )
  }

  publishOnYouTube(videoId, refreshToken) {
    console.log(videoId)
    let payLoad = {
      id: videoId,
      status: { privacyStatus: 'public' },
    }
    const headerDict = { Authorization: 'Bearer ' + refreshToken }
    const requestOptions = { headers: new HttpHeaders(headerDict) }
    let url = `https://youtube.googleapis.com/youtube/v3/videos?part=status&key=${this.youtubeApiKey}`
    return this.http.put(url, payLoad, requestOptions).pipe(
      map(res => {
        return res
      }),
    )
  }

  getRefreshToken(code) {
    const payLoad = {
      code: code,
      grant_type: 'authorization_code',
      redirect_uri: window.location.origin,
      client_id: this.youtubeClientId,
      client_secret: this.youtubeClientSecret,
    }
    return this.http.post(`https://accounts.google.com/o/oauth2/token`, payLoad)
  }

  getAuthBearerToken(refreshToken) {
    const payLoad = {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      redirect_uri: window.location.origin,
      client_id: this.youtubeClientId,
      client_secret: this.youtubeClientSecret,
    }
    return this.http.post(`https://accounts.google.com/o/oauth2/token`, payLoad)
  }

  saveLinkedAppData(payLoad) {
    return this.http.post(this._saveLinkedAppData, payLoad, httpOptions)
  }
  insertNewDocument(reqObject) {
    return this.http.post(this._insertNewDocument, reqObject)
  }

  removeYoutubeAccess() {
    const accessToken = localStorage.getItem('youtubeAccessToken')
    var urlencoded = new URLSearchParams()
    return this.http.get(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`)
  }


  moveCommentWithCurrentVer(document) {
    return this.http.post<any>(this._moveComment, { doc: document }, httpOptions)
  }

  exchangeBoxCodeForToken(code) {
    let httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    }
    let body = `client_id=${this.boxClientId}&code=${code}&redirect_uri=${this.boxRedirectUri}&grant_type=authorization_code&client_secret=${this.boxClientSecret}`;
    return this.http.post(`${this.boxURL}oauth2/token`, body, httpOpt)
  }

  exchangeBoxRefreshTokenForToken(code) {
    let httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    }
    let body = `client_id=${this.boxClientId}&refresh_token=${code}&redirect_uri=${this.boxRedirectUri}&grant_type=refresh_token&client_secret=${this.boxClientSecret}`;
    return this.http.post(`${this.boxURL}oauth2/token`, body, httpOpt)
  }

  getBoxDocList(folderid) {
    let httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('boxAccessToken'),
      }),
    }
    return this.http.get(`${this.boxURL}2.0/folders/${folderid}/items?offset=0&limit=10000`, httpOpt)
  }


  boxSharedLink(fileId) {
    let httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('boxAccessToken'),
      }),
    }

    // const payLoad  = {
    //   "shared_link": {
    //     "access": "open",
    //     "permissions": {
    //     "can_download": true,
    //     "can_edit": true,
    //     "can_preview": true
    //     }
    //   }
    // }

    return this.http.get(`${this.boxURL}2.0/files/${fileId}`, httpOpt)
  }


  getBoxTokenForOtherUser(ownerId, fileId) {
    return this.http.get(this._boxAccessTokenUrl + ownerId + '/' + fileId)
  }

  unlinkBoxFolder(folderId) {
    return this.http.get(this._boxFolderUnlink + folderId)
  }

  mkBoxHookForFolder(boxFolderId) {

    let httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('boxAccessToken'),
      }),
    }

    let payload = {
      "target": {
        "id": boxFolderId,
        "type": "folder"
      },
      "address": this.boxWebHookUrk + boxFolderId,
      "triggers": [
        "FILE.DELETED", "FILE.MOVED", "FILE.RENAMED", "FILE.RESTORED", "FILE.TRASHED", "FILE.UPLOADED",
        "FOLDER.RENAMED", "FOLDER.MOVED", "FOLDER.RESTORED", "FOLDER.TRASHED"
      ]
    }
    return this.http.post(`https://api.box.com/2.0/webhooks`, payload, httpOpt)
  }

  validateUser(appName: string) {
    return this.http.get(this._validateBoxUser + appName)
  }

  addWorkflow(docObj) {
    return this.http.post<any>(this._workflow, docObj, httpOptions)
  }

  getWorkflowDataById(id : any) {
    return this.http.get<any>(`${this._getWorkflowList}/${id}`, httpOptions)
  }
  workflowList(workflowObj) {
    return this.http.post<any>(this._getWorkflowList, workflowObj, httpOptions)
  }
  workflowDelete(workflowObj) {
    return this.http.post<any>(this._deleteWorkFlow, workflowObj, httpOptions)
  }
  getStorageInfoOfWorkspaceOwner(ownerId){
    return this.http.post<any>(this.getWorkspaceOwnerStorage, {ownerId}, httpOptions)
  }
  checkFileExist(fileEntries){
    return this.http.post(this._checkFileExist, fileEntries, httpOptions)
  }
  getTotalTeamMember(docObj) {
    return this.http.post<any>(this._getTotalTeamMember, docObj, httpOptions)
  }
  applyApprovalForkflow(docObj){
    return this.http.post<any>(this._applyApprovalWorkflow, docObj, httpOptions)
  }
  excuteApprovalWorkflow(docObj){
    return this.http.get<any>(`${this._excuteApprovalWorkflow}/${docObj.userId}/${docObj.documentId}/${docObj.versionId}`, httpOptions)
  }
  getworkflowIdBydocId(id){
    return this.http.post<any>(this._getworkflowIdBydocId, id, httpOptions)
  }

  getworkflowIdByfolderId(id){
    return this.http.post<any>(this._getworkflowIdByfolderId, id, httpOptions)
  }

  getWorkflowCount(){
    return this.http.get<any>(this._workflowCount, httpOptions)
  }

  getOtherUsersStorageInfo(userInfo: any) {
    return this.http.post<any>(this.getOtherUserStorage,userInfo, httpOptions)
  }

  getworkflowIdBydocumentId(id){
    return this.http.post<any>(this._getworkflowDetails, id, httpOptions)
  }

  changeVimeoprivacyStatus(videoId: Number) {
    let vimeoToken = localStorage.getItem('vimeo')
    let raw = { privacy: { view: 'anybody' } }
    const headerDict = {
      Authorization: 'Bearer ' + vimeoToken,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.video+json',
    }
    const requestOptions = { headers: new HttpHeaders(headerDict) }
    return this.http
      .patch(`https://api.vimeo.com/videos/${videoId}`, JSON.stringify(raw), requestOptions)
      .pipe(
        map(res => {
          return res
        }),
      )
  }


  getVimeoProjectById(arg) {

    let videoObject: any = ''
    videoObject = localStorage.getItem('vimeo')
    if (videoObject != '') {
      videoObject = JSON.parse(videoObject)
      const headerDict = {
        Authorization: 'Bearer ' + videoObject.access_token,
        'Content-Type': 'application/vnd.vimeo.video+json',
      }
      const requestOptions = { headers: new HttpHeaders(headerDict)}
      return this.http.get(`https://api.vimeo.com/me/projects/${arg}/videos`, requestOptions).pipe(
        map(res => {
          return res
        }),
      )
    }
  }
}
