import { Component, OnInit } from '@angular/core'
import { Router, RoutesRecognized } from '@angular/router'
import { reduce } from 'lodash'
import { MenuService } from 'src/app/services/menu'
import { DataService } from 'src/app/services/data.service'
import { GlobalService } from 'src/app/services/common/global.service'
import { AuthService } from 'src/app/services/common/auth.service'
import { CommonDocumentService } from 'src/app/services/common/common-document.service'
import { UploadService } from 'src/app/services/upload.service'
import { FileUploader } from 'ng2-file-upload'
import { NzModalService } from 'ng-zorro-antd/modal'
import { WorkflowDialogComponent } from 'src/app/pages/dashboard/modal-components/workflow-dialog/workflow-dialog.component'
import { ConfirmationDialogComponent } from 'src/app/pages/confirmationDialogModule/confirmation-dialog/confirmation-dialog.component'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { PouchDataService } from 'src/app/services/pouch-data.service'
import { DocumentService } from 'src/app/services/document.service'
import { FolderService } from 'src/app/services/folder.service'
import { ShareDialogComponent } from 'src/app/pages/share-dialog/share-dialog.component'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { MoveFileComponent } from 'src/app/pages/dashboard/modal-components/move-file/move-file.component'
import { DownloadService } from 'src/app/services/common/download.service'

@Component({
  selector: 'qr-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  menuData: any[]
  breadcrumbs: any[]
  setIsVisible: boolean = false
  viewBg: boolean = false
  tooltipView: string = 'off'
  tooltipView2: string = 'off'
  detailInfo: boolean = false
  switchTableView: boolean = false
  viewLayout: boolean = false
  moreActions: string = 'off'
  setDisplay
  url
  gridView: boolean = true
  listView: boolean = false
  currentPage: string
  docDetail: any
  currentSelectedItem: any = null
  treeObj: any = null
  newNode: any = []
  currentNode: any = null
  folderObj: Array<any>
  docInfo: Array<any>
  dropMenu: string = 'off'
  selectedDocumentArr: any = []
  selectedFolderArr: any = []
  starBtn: boolean
  sharedStatus: boolean = false
  currentUser: any
  contextMenuEvent: any
  folderBCObj: any = []
  dropBoxFolderBCObj: any = []
  boxFolderBCObj:any = []
  dropBoxFolderObj: any = []
  dropBoxFileObj: any = []
  youTubeFileObj: any = []
  youTubeFolderBCObj: any = []
  channelName: any = null
  isAuthenticated: boolean
  isAuthenticatedDropBox: boolean
  uploadSourceCurrentFolder: string
  dropboxDisconnect: string = 'off'
  searchItemPathArr: any = []
  sortBy: string
  currentUrl: string
  uploaderVersion
  isMobileView: boolean
  fileUploadSource = 'qr'
  isReviewer: boolean
  isTeamMember: boolean = false
  downloadPermisson = false;
  settings : any;
  vimeoFolderBCObj: any = []
  constructor(
    private _uploadService: UploadService,
    private menuService: MenuService,
    private _docService: DocumentService,
    private router: Router,
    private globalService: GlobalService,
    private _authService: AuthService,
    private _folderService: FolderService,
    public _dataService: DataService,
    private commonDocService: CommonDocumentService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private _pouchDataService: PouchDataService,
    private store: Store<any>,
    private downloadService: DownloadService,
  ) { }

  ngOnInit(): void {

    this.globalService.customAppSetting.subscribe(userSetting => {
      this.isTeamMember = (userSetting.appUrlOwnerId == null || userSetting.appUrlOwnerId == userSetting.userId ? false : true)
    })

    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.isMobileView = state.isMobileView
    })
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state
    })
    this.globalService.channelName.subscribe(channelName => {
      this.channelName = channelName
    })
    this.globalService.youTubeFolderBCObj.subscribe(
      youTubeFolderBCObj => (this.youTubeFolderBCObj = youTubeFolderBCObj),
    )
    this.globalService.vimeoFolderBCObj.subscribe(
      vimeoFolderBCObj => (this.currentPage=='vimeo' ? (this.vimeoFolderBCObj = vimeoFolderBCObj) : []),
    )
    this.globalService.searchItemPathArr.subscribe(searchItemPathArr => {
      if (searchItemPathArr.length > 0) {
        var tmp = []
        searchItemPathArr.forEach(element => {
          var obj = {
            folderId: element.folder_id,
            folderName: element.folder_name,
            userId: element.user_id,
            workspaceId: 0,
          }
          element.folder_name.toLowerCase() != 'my documents' ? tmp.push(obj) : ''
        })
        // this.folderBCObj = tmp
      }
    })
    // this.uploaderVersion = this._uploadService.uploader
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.generateBreadcrumbs(this.router.url)

    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationStart))
    //   .subscribe((event: NavigationStart) => {
    //     this.generateBreadcrumbs(event.url ? event.url : null)
    //   })
    this.currentUrl = this.router.url
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.currentUrl = event.urlAfterRedirects
      }
    })

    this.globalService.contextMenuEvent.subscribe(
      contextMenuEvent => (this.contextMenuEvent = contextMenuEvent),
    )
    this.globalService.docDetail.subscribe(docDetail => (this.docDetail = docDetail))
    this.globalService.isReviewer.subscribe(isReviewer => (this.isReviewer = isReviewer))
    this.globalService.moreActions.subscribe(resp => (this.moreActions = resp))
    this.globalService.setVisible.subscribe(resp => {
      this.setIsVisible = resp
    })
    this.globalService.setTableView.subscribe(resp => (this.switchTableView = resp))
    this.globalService.currentSelectedItem.subscribe(
      currentSelectedItem => (this.currentSelectedItem = currentSelectedItem),
    )
    this.globalService.treeObj.subscribe(treeObj => (this.treeObj = treeObj))
    this.globalService.newNode.subscribe(newNode => (this.newNode = newNode))
    this.globalService.currentNode.subscribe(currentNode => (this.currentNode = currentNode))
    this.globalService.folderObj.subscribe(folderObj => (this.folderObj = folderObj))
    this.globalService.docInfo.subscribe(docInfo => (this.docInfo = docInfo))
    this.globalService.selectedDocumentArr.subscribe(selectedDocumentArr => {
      this.selectedDocumentArr = selectedDocumentArr
      if (selectedDocumentArr.length > 0) {
        this.fileUploadSource = 'qr'
        
        for (let i = 0; i < selectedDocumentArr.length; i++) {
          if(this.currentUser.userId == selectedDocumentArr[i].userId && selectedDocumentArr[i].download == 0 && !this.downloadPermisson){
            this.downloadPermisson=true
          }
          if (selectedDocumentArr[i].uploadSource === 'Dropbox') {
            this.fileUploadSource = 'Dropbox'
          }
          if (selectedDocumentArr[i].uploadSource === 'youtube') {
            this.fileUploadSource = 'youtube'
          }
          if (selectedDocumentArr[i].uploadSource === 'box') {
            this.fileUploadSource = 'box'
          }
        }
      }
    })
    this.globalService.selectedFolderArr.subscribe(
      selectedFolderArr => (this.selectedFolderArr = selectedFolderArr),
    )
    this.globalService.starBtn.subscribe(starBtn => {
      this.starBtn = starBtn
    })
    this.globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    this.globalService.sharedStatus.subscribe(sharedStatus => (this.sharedStatus = sharedStatus))
    this.globalService.gridView.subscribe(gridView => (this.gridView = gridView))
    this.globalService.listView.subscribe(listView => (this.listView = listView))
    this.globalService.folderBCObj.subscribe(folderBCObj => (this.folderBCObj = folderBCObj))
    this.globalService.dropBoxFolderBCObj.subscribe(
      dropBoxFolderBCObj => (this.dropBoxFolderBCObj = dropBoxFolderBCObj),
    )
    this.globalService.boxFolderBCObj.subscribe(
      boxFolderBCObj => (this.boxFolderBCObj = boxFolderBCObj),
    )
    this.globalService.dropBoxFolderObj.subscribe(
      dropBoxFolderObj => (this.dropBoxFolderObj = dropBoxFolderObj),
    )
    this.globalService.dropBoxFileObj.subscribe(
      dropBoxFileObj => (this.dropBoxFileObj = dropBoxFileObj),
    )
    this.globalService.sortBy.subscribe(sort => (this.sortBy = sort))
    this.url = this.router.url
    this.globalService.isAuthenticated.subscribe(
      isAuthenticated => (this.isAuthenticated = isAuthenticated),
    )
    this.globalService.isAuthenticatedDropBox.subscribe(
      isAuthenticatedDropBox => (this.isAuthenticatedDropBox = isAuthenticatedDropBox),
    )
    if (localStorage.getItem('sortBy')) {
      this.sortBy = localStorage.getItem('sortBy')
      this.globalService.sortBy.next(localStorage.getItem('sortBy'))
    } else {
      this.sortBy = 'modifiedOn'
      this.globalService.sortBy.next('modifiedOn')
    }
    this.globalService.uploadSourceCurrentFolder.subscribe(
      uploadSourceCurrentFolder => (this.uploadSourceCurrentFolder = uploadSourceCurrentFolder),
    )

    this.globalService.userInfo.subscribe(userInfo => (this.currentUser = userInfo))
  }

  generateBreadcrumbs(event: any) {
    this.breadcrumbs = this.getPath(this.menuData, event).reverse()
  }

  getPath(data: any[], url: string, parents = []) {
    const items = reduce(
      data,
      (result: any, entry: any) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = this.getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter((e: any) => !!e))
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : [false]
  }

  onViewDetail() {
    if (this.contextMenuEvent) {
      this.contextMenuEvent.close(true)
    }

    this.globalService.currentSelectedItem.subscribe(currentSelectedItem => {
      this.currentSelectedItem = currentSelectedItem
    })

    if(this.currentSelectedItem=='Folder'){
      this._dataService.getFolderDetail().then(() => {
        // Get the folder log details
        this._dataService.getFolderLogDetail()
        if (this.globalService.iFramed == true) {
          window.parent.postMessage(
            { selectedFolder: this.globalService.folderDetail.value },
            '*',
          )
        }
      })
    }else{
      this._dataService.getDocDetail().then(result => {
        this._dataService.getLogDetail()
        if (this.globalService.iFramed == true) {
          window.parent.postMessage({ selectedDocument: this.globalService.docDetail.value }, '*')
        }
      })
    }
    this.setIsVisible = !this.setIsVisible
    this.globalService.setVisible.next(this.setIsVisible)
    this.viewBg = !this.viewBg
    this.detailInfo = !this.detailInfo
    this.tooltipView = 'off'
    this.switchTableView = !this.switchTableView
    this.globalService.setTableView.next(this.switchTableView)
    if (!this.setIsVisible) {
      //this.docDetail = null;
    }
    this.globalService.menu.next('off')
  }

  viewDetailTooltip(e) {
    if (e.type === 'mouseenter') {
      this.tooltipView = 'on'
    } else if (e.type === 'mouseleave') {
      this.tooltipView = 'off'
    }
  }

  listGridTooltip(e) {
    if (e.type === 'mouseenter') {
      this.tooltipView2 = 'on'
    } else if (e.type === 'mouseleave') {
      this.tooltipView2 = 'off'
    }
  }

  changeView() {
    this.tooltipView2 = 'off'
    this.viewLayout = !this.viewLayout
    this.gridView = !this.gridView
    this.listView = !this.listView
    this.globalService.showLoader.next(true)

    if (this.gridView) {
      localStorage.setItem(`${this.currentUser.userId}-view`, 'grid')
      this._authService.setView({ viewType: 'grid' }).subscribe(
        () => {
          this.globalService.showLoader.next(false)
        },
        err => {
          //console.log(err)
          this.globalService.showLoader.next(false)
        },
      )
    } else if (this.listView) {
      localStorage.setItem(`${this.currentUser.userId}-view`, 'list')
      this._authService.setView({ viewType: 'list' }).subscribe(
        () => {
          this.globalService.showLoader.next(false)
        },
        err => {
          this.globalService.showLoader.next(false)
          //console.log(err)
        },
      )
    }

    this.globalService.gridView.next(this.gridView)
    this.globalService.listView.next(this.listView)

    // this.globalService.selectedItem.next([]);
    // this.globalService.selectedFolderItem.next([]);
    this.globalService.moreActions.next(this.moreActions)
  }

  onMenu(e) {
    this.dropMenu == 'off' ? (this.dropMenu = 'on') : (this.dropMenu = 'off')
    this.globalService.menu.next(this.dropMenu)
  }

  quickShare(element) {
    // const editEmailRef = this.modalService.create({
    //   nzTitle: '',
    //   nzContent: ShareDialogComponent,
    //   nzComponentParams: {
    //     data: element,
    //   },
    //   nzFooter: null,
    //   nzWidth: 850,
    //   nzStyle: { top: '25px', height: '80%' },
    // })
    this._dataService.quickShare()
  }

  rename() {
    const currentSelectedNode = this.getCurrentNode()
    this._dataService.renameElement(currentSelectedNode)
  }

  updateVersion() {
    let docData = this.selectedDocumentArr[0]
    this.globalService.currentDocument.next(docData.documentId)
    this.globalService.currentVersion.next(parseInt(docData.versionNo) + 1)
    this.globalService.menu.next('off')
  }

  getCurrentNode() {
    let currentSelectedNode = {}
    if (this.currentSelectedItem === 'Folder') {
      currentSelectedNode = this.selectedFolderArr[0]
    } else {
      currentSelectedNode = this.selectedDocumentArr[0]
    }
    return currentSelectedNode
  }

  getCurrentMultipleNodes() {
    let currentSelectedNode = []
    if (this.currentSelectedItem === 'Folder') {
      currentSelectedNode = this.selectedFolderArr
    } else {
      currentSelectedNode = this.selectedDocumentArr
    }
    return currentSelectedNode
  }

  trashFile() {
    const currentSelectedNode = this.getCurrentNode()
    this._dataService.trashElement(currentSelectedNode)
  }

  incrementVersionElement() {
    let currentSelectedNode = this.getCurrentNode()
    if (!currentSelectedNode) {
      const tmp = this.globalService.docDetail.value
      currentSelectedNode = {
        userId: tmp.user_id,
        documentId: tmp.document_id,
        versionNo: this.globalService.documentVersionsArr.value.length,
        folderId: tmp.folder_id,
        workspaceId: tmp.workspace_id,
        documentFileName: tmp.DocumentVersions[tmp.DocumentVersions.length - 1].document_file_name,
        documentTitle: tmp.document_title,
      }
    }
    const priviledged = this.currentUser.membershipId > 1 ? true : false

    this.commonDocService.increaseDocumentVersion(currentSelectedNode, priviledged, this.docInfo)
  }

  restoreFile() {
    this._dataService.restoreFile()
  }

  deleteFile() {
    this._dataService.deleteFile()
  }

  emptyTrash(e) {
    const confirmationDialog = this.modalService.create({
      nzTitle: 'Empty Trash',
      nzContent: ConfirmationDialogComponent,
      nzComponentParams: {
        message:
          'This is an irreversible action. Are you sure you want to delete the documents permanently?',
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
    })
    confirmationDialog.afterClose.subscribe(result => {
      if (result) {
        this.emptyFile(e)
      }
    })
  }

  emptyFile(e) {
    this._docService.emptyTrash().subscribe(
      res => {
        this._pouchDataService.emptyFileData('trash')
        if (this.currentPage === 'trash') {
          this.docInfo = []
          this.globalService.docInfo.next(this.docInfo)
          this.globalService.trashDocInfo.next(false)
        }
        this._dataService.storagedocs()
      },
      err => {},
    )
    this._folderService.emptyTrash().subscribe(
      res => {
        this._pouchDataService.emptyData('trash')
        if (this.currentPage === 'trash') {
          this.folderObj = []
          this.globalService.folderObj.next(this.folderObj)
          this.globalService.trashFolderInfo.next(false)
        }
        this._dataService.storagedocs()
        // this.snackBar.open('Trash emptied.', '', { duration: 3000 });
        this.notification.create('success', null, 'Trash emptied.', { nzPlacement: 'bottomRight' })
      },
      err =>
        this.notification.create('error', null, err.error.failed, { nzPlacement: 'bottomRight' }),
    )
  }

  openPreview() {
    const currentSelectedNode = this.getCurrentNode()
    this._dataService.openPreview(currentSelectedNode)
  }

  clickOutsideCard() {
    this._dataService.resetAllObservable()
  }

  addStar(e) {
    e.preventDefault()
    this._dataService.addStarMulti()
  }

  removeStar(e) {
    e.preventDefault()
    this._dataService.removeStarMulti()
  }

  workFlowStatus() {
    const workflowStatusRef = this.modalService.create({
      nzTitle: 'Change Status',
      nzContent: WorkflowDialogComponent,
      nzFooter: null,
      nzWidth: 500,
      nzMaskClosable: false,
    })
    workflowStatusRef.afterClose.subscribe(result => {
      if (result) {
        this._dataService
          .parseArr()
          .then(docObjArr => {
            const docObj = {
              documentId: docObjArr['docIdArr'],
              versionId: docObjArr['verIdArr'],
              uploadSource: docObjArr['uploadSourceArr'],
            }

            const obj = {
              workflow_status_id: result.workflow_status_id,
              status_name: result.status_name,
            }

            this._dataService.changeStatus(docObj, obj)
          })
          .catch(err => {})
      }
    })
  }

  downloadElement(e) {
    e.preventDefault()
    //this._dataService.downloadElementMulti()
    this.downloadService.downloadElementMulti()
  }

  setExpiry(e) {
    this._dataService.setExpiry()
  }

  moveFile() {
    // ToDo code for moving multiple files at once.
    const docArr = this.selectedDocumentArr
    const addWorkspaceFolderRef = this.modalService.create({
      nzContent: MoveFileComponent,
      nzComponentParams: {},
      nzFooter: null,
      nzTitle: 'Move File',
      nzWidth: 300,
      nzClassName: 'moveFile',
      nzBodyStyle: { padding: '24px 15px', 'border-radius': '8px' },
    })
    addWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) this._dataService.moveFileMulti(result)
    })
    this.globalService.menu.next('off')
  }

  versionInfo() {
    this._dataService.getDocVersions()
    const currentSelectedNode = this.getCurrentNode()
    this._dataService.versionInfo(currentSelectedNode)
  }

  // disconnectDropbox() {
  //   this._dataService.disconnectFromDropbox();
  // }

  disconnectDropbox() {
    // const dialogRef = this.dialog.open(DropboxDisconnectDialogComponent, {
    //   width: '500px',
    //   maxWidth: '95vw',
    //   disableClose: true,
    // })
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) this.removeDropboxAccount(e)
    // })
  }

  removeDropboxAccount() {
    // this.loaderService.show()
    // this._docService.removeDropboxAccount({ folderId: 0, uploadSource: 'Dropbox' }).subscribe((response) => {
    //   this.globalService.isAuthenticated.next(false)
    //   this.globalService.isAuthenticatedDropBox.next(false)
    //   this.isAuthenticated = false
    //   this.globalService.dropBoxFileObj.next([])
    //   this.globalService.dropBoxFolderObj.next([])
    //   this.globalService.dropBoxFolderBCObj.next([])
    //   localStorage.removeItem('dropBoxAccessToken')
    //   this.snackBar.open('Dropbox Account has been removed.', '', { duration: 3000 })
    //   var dbx = new Dropbox.Dropbox({ clientId: environment.dropBoxKey })
    //   var authUrl = dbx.getAuthenticationUrl(environment.rmUrl)
    //   this.globalService.authUrl.next(authUrl)
    //   this.loaderService.hide()
    //   this.router.navigate(["/dashboard/integration"])
    // }, err => console.log('err', err))
  }
  sortChange(event) {
    this.sortBy = event
    this.globalService.sortBy.next(event)
    localStorage.setItem('sortBy', event)
    // debugger;
    // this._dataService.sortBy(this.sortBy)
    // this.dropBoxFileObj.sort((a, b) => { if (a.createdOn < 0) {return -1; } if (b.createdOn <0 ) {return 1;} return b.createdOn - a.createdOn });
  }
  openWorkflow() {
    const currentSelectedNode = this.getCurrentNode()
    this._dataService.openWorkflow(currentSelectedNode)
  }
}
